import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ListIcon from "@mui/icons-material/List";
import GAPriceCard from "./GAPriceCard";
import * as _ from "lodash";
import ReactECharts from "echarts-for-react";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { baseUrl } from "../data/consts";

interface ProductInfoProps {
  id: string;
  setSelectedProductId: any; // function
}

const ProductInfo: React.FC<ProductInfoProps> = (props: ProductInfoProps) => {
  const [product, setProduct] = useState<any>(null);
  const [priceGraphData, setPriceGraphData] = useState<any>(null);
  const [images, setImages] = useState<any>(null);

  const nutritionFields = [
    "energy",
    "protein",
    "fatTotal",
    "fatSaturated",
    "carbohydrate",
    "carbohydrateSugar",
    "dietaryFibre",
    "sodium",
  ];

  // Fetch product data upon load
  useEffect(() => {
    axios
      .get(`${baseUrl}/customerUnifiedProducts/${props.id}`)
      .then((res) => {
        const tempProduct = res.data;

        const priceData: any = {
          dates: [],
          coles: [],
          woolworths: [],
        };

        // Extract dates
        const datesColes: Array<string> = tempProduct.prices.COLES.map(
          (priceObject: any) => new Date(priceObject.date).toLocaleDateString()
        );
        const datesWoolworths: Array<string> =
          tempProduct.prices.WOOLWORTHS.map((priceObject: any) =>
            new Date(priceObject.date).toLocaleDateString()
          );
        priceData.dates = Array.from(
          new Set(datesColes.concat(datesWoolworths))
        ).sort((a: string, b: string) => (a < b ? -1 : 1));

        // Extract Coles prices
        priceData.coles = Array(priceData.dates.length).fill(null);
        for (let priceObject of tempProduct.prices.COLES) {
          const colesDate = new Date(priceObject.date).toLocaleDateString();
          const dateIndex = priceData.dates.indexOf(colesDate);
          priceData.coles[dateIndex] = priceObject.price;
        }

        // Extract Woolworths prices
        priceData.woolworths = Array(priceData.dates.length).fill(null);
        for (let priceObject of tempProduct.prices.WOOLWORTHS) {
          const woolworthsDate = new Date(
            priceObject.date
          ).toLocaleDateString();
          const dateIndex = priceData.dates.indexOf(woolworthsDate);
          priceData.woolworths[dateIndex] = priceObject.price;
        }

        // TODO: Fill in missing prices (needs to be set to the last NORMAL price)

        // // Images
        // const images = tempProduct.images.map((url: string) => {
        //   // return {
        //   //   original: `${process.env.PUBLIC_URL}/images/${url}`,
        //   //   thumbnail: `${process.env.PUBLIC_URL}/images/${url}`,
        //   // };
        // });

        setImages([
          {
            original: "placeholder-image.png",
            thumbnail: "placeholder-image.png",
          },
        ]);
        setPriceGraphData(priceData);
        setProduct(res.data);
      })
      .catch((err) => {
        console.error("Something gone wrong");
        console.error(err);
      });
  }, [props.id]);

  // Price History Chart
  const options = {
    title: {
      text: "Price History",
    },
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["COLES", "WOOLWORTHS"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: priceGraphData?.dates ? priceGraphData.dates : [],
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "${value}",
      },
    },
    series: [
      {
        name: "COLES",
        type: "line",
        step: "start",
        itemStyle: { color: "#d32f2f" },
        lineStyle: { color: "#d32f2f" },
        data: priceGraphData?.coles ? priceGraphData.coles : [],
      },
      {
        name: "WOOLWORTHS",
        type: "line",
        step: "start",
        itemStyle: { color: "#689f38" },
        lineStyle: { color: "#689f38" },
        data: priceGraphData?.woolworths ? priceGraphData.woolworths : [],
      },
    ],
  };

  return (
    <>
      {!product ? (
        "Loading"
      ) : (
        <div>
          <Grid container direction="row" justifyContent="space-evenly">
            <Grid item xs={12}>
              <Tooltip title={"Close"}>
                <IconButton
                  onClick={() => {
                    props.setSelectedProductId("");
                  }}
                  style={{ float: "right" }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Report Issue"}>
                <IconButton
                  onClick={() => {
                    console.log("REPORT ERROR");
                  }}
                  style={{ float: "right" }}
                >
                  <ErrorOutlineIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Add to Favourites"}>
                <IconButton
                  onClick={() => {
                    console.log("FAVOURITE PRODUCT");
                  }}
                  style={{ float: "right" }}
                >
                  <FavoriteBorderIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Add to List"}>
                <IconButton
                  onClick={() => {
                    console.log("ADD TO LIST");
                  }}
                  style={{ float: "right" }}
                >
                  <ListIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={4} style={{ border: "solid" }}>
              <ReactImageGallery
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
              />
            </Grid>
            <Grid item xs={8} style={{ border: "solid" }}>
              <h2>{product.name}</h2>
              <Box
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 10,
                }}
              >
                {Object.keys(product.price).map((store) => {
                  return (
                    <GAPriceCard store={store} {...product.price[store]} />
                  );
                })}
              </Box>
              <Box style={{ padding: 20 }}>
                <ReactECharts option={options} />
              </Box>
            </Grid>
            <Grid item xs={12} style={{ border: "solid" }}>
              <h3>Description</h3>
              <Typography>
                {product.description
                  ? product.description
                  : "No description..."}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ border: "solid" }}>
              <h3>Ingredients</h3>
              <Typography>
                {product.ingredients
                  ? product.ingredients
                  : "No ingredients..."}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ border: "solid" }}>
              <h3>Nutrition</h3>

              <Grid container direction="row" justifyContent="space-evenly">
                <Grid xs={6}>
                  <Table size="small">
                    <TableHead>
                      <TableCell></TableCell>
                      <TableCell>Qty Per Serving</TableCell>
                      <TableCell>Qty Per 100g / 100ml</TableCell>
                    </TableHead>
                    <TableBody>
                      {nutritionFields.map((key, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{_.startCase(key)}</TableCell>
                          <TableCell>
                            {product.nutrition[`${key}PerServing`]}
                          </TableCell>
                          <TableCell>
                            {product.nutrition[`${key}Per100`]}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid xs={6}>
                  <Typography>{`Servings: ${product.nutrition.servings}`}</Typography>
                  <Typography>{`Serving Size: ${product.nutrition.servingSize}`}</Typography>
                  <Typography>{`Health Star Rating: ${product.nutrition.healthStarRating}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ProductInfo;
