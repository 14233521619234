import * as React from 'react';
import { Box } from '@mui/material'
import ProductGrid from './ProductGrid';
import ProductInfo from './ProductInfo';

interface ProductViewProps {
    products: any
    selectedProductId: string
    setSelectedProductId: any
}

const ProductView: React.FC<ProductViewProps> = (props: ProductViewProps) => {
    return (
        <Box className="App" style={{ width: 1200, margin: 'auto' }}>
            {props.selectedProductId ?
                <ProductInfo id={props.selectedProductId} setSelectedProductId={props.setSelectedProductId}></ProductInfo>
                :
                <ProductGrid products={props.products} setSelectedProductId={props.setSelectedProductId} />
            }
        </Box >
    )
}


export default ProductView;



