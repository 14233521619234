import * as React from "react";
import "./App.css";
import ProductView from "./components/ProductView";
import CssBaseline from "@mui/material/CssBaseline";
import {
  AppBar,
  Autocomplete,
  Badge,
  Box,
  Card,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { AccountCircle, Notifications } from "@mui/icons-material";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ListIcon from "@mui/icons-material/List";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { baseUrl } from "./data/consts";

const queryClient = new QueryClient();

function App() {
  const [suggestions, setSuggestions] = React.useState<Array<any>>([]);
  const [products, setProducts] = React.useState<any>([]);
  const [selectedProductId, setSelectedProductId] = React.useState("");
  const [isLoadingSearch, setIsLoadingSearch] = React.useState(false)

  const onInputChange = useDebouncedCallback((value: string) => {
    if (!value) {
      return [];
    }

    const params = {
      params: {
        query: value,
      }
    };

    axios
      .get(`${baseUrl}/customerUnifiedProducts/autoComplete`, params)
      .then((res) => {
        const tempSuggestions: any[] = [];

        for (let suggestion of res.data) {
          tempSuggestions.push({
            id: suggestion.id,
            label: suggestion.name,
            image: suggestion.image,
          });
        }

        setSuggestions(tempSuggestions);
      })
      .catch((err) => {
        console.error("Something gone wrong");
        console.error(err);
      });
  }, 250);

  const onChange = (value: any) => {
    setSelectedProductId("");

    if (value === null) {
      setProducts([]);
    }

    // If it's a string search for the products
    if (typeof value === "string") {
      console.log("searching")
      setIsLoadingSearch(true)

      const params = {
        params: {
          query: value,
        },
      };

      axios
        .get(`${baseUrl}/customerUnifiedProducts/search`, params)
        .then((res) => {
          setProducts(res.data);
          setIsLoadingSearch(false)
        });
    } else {
      setSelectedProductId(value.id);
    }
  };

  return (
    <>
      <CssBaseline />
      <Box style={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              style={{ flexGrow: 1 }}
            >
              Half Price Groceries
            </Typography>

            <Autocomplete
              id="product-auto-complete"
              style={{ width: "750px" }}
              freeSolo={true}
              onInputChange={(event: any, value: string) =>
                onInputChange(value)
              }
              onChange={(event: any, value: any) => onChange(value)}
              options={suggestions}
              size="small"
              renderInput={(params) => (
                <Card sx={{ padding: '8px' }}>
                  <TextField {...params} label="Enter product name..." />
                </Card>
              )}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={
                      option.image
                        ? `${process.env.PUBLIC_URL}/images/${option.image}`
                        : "placeholder-image.png"
                    }
                    alt=""
                  />
                  {option.label}
                </Box>
              )}
            />
            <Box style={{ flexGrow: 1 }} />
            <Box sx={{ width: '200px' }}>
              {/* <Tooltip title="Notifications" style={{ marginRight: "10px" }}>
                <IconButton color="inherit" disabled>
                  <Badge>
                    <Notifications />
                  </Badge>
                </IconButton>
              </Tooltip>

              <Tooltip title="Profile">
                <IconButton color="inherit">
                  <AccountCircle />
                </IconButton>
              </Tooltip> */}
            </Box>
          </Toolbar>
        </AppBar>

        {/* <Drawer variant="permanent">
          <Box style={{ overflow: "auto", marginTop: "80px", width: "200px" }}>
            <List>
              <ListItem key={"Favourites"} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <FavoriteIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Favourites"} />
                </ListItemButton>
              </ListItem>

              <ListItem key={"Lists"} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Lists"} />
                </ListItemButton>
              </ListItem>

              <Divider />

              <ListItem key={"Products"} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ShoppingBagIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Products"} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer> */}
      </Box>

      <Box
        component="main"
        style={{ marginTop: "90px", flexGrow: 1 }}
      >
        {isLoadingSearch && <Typography align="center">Loading search results...</Typography>}
        {!isLoadingSearch && <ProductView
          products={products}
          selectedProductId={selectedProductId}
          setSelectedProductId={setSelectedProductId}
        />}

      </Box>
    </>
  );
}

export default App;
