import { Box, Grid } from "@mui/material"
import GAProductCard from "./GAProductCard"

interface Products {
    id: string
    name: string
    image?: string
    price: any
}

interface ProductGridProps {
    products: Products[]
    setSelectedProductId: any // function
}

const ProductGrid: React.FC<ProductGridProps> = (props: ProductGridProps) => {
    const fakeProduct = {
        "id": "",
        "name": "Fake Product",
        "price": {
            "COLES": {
                "price": 0,
                "priceNew": 0,
                "event": "NORMAL"
            },
            "WOOLWORTHS": {
                "price": 0,
                "priceNew": 0,
                "event": "NORMAL"
            }
        },
    }

    return (
        <Grid container direction="row" justifyContent="space-evenly">
            {props.products.map((product) => {
                console.log(product)
                return <GAProductCard
                    {...product}
                    setSelectedProductId={props.setSelectedProductId}
                />
            })}
            {
                Array.from(Array(4 - (props.products.length % 4))).map((value) => {
                    return <Box key={value} style={{ visibility: 'hidden' }}  >
                        <GAProductCard {...fakeProduct} setSelectedProductId={props.setSelectedProductId} />
                    </Box>
                })
            }
        </Grid>
    )
}

export default ProductGrid