import * as React from "react";
import { Card, Typography, Box } from "@mui/material";
import GAPriceCard from "./GAPriceCard";

interface GAProductCardProps {
  id: string;
  name?: string;
  image?: string;
  price?: any;
  setSelectedProductId: any; // function
}

const GAProductCard: React.FC<GAProductCardProps> = (
  props: GAProductCardProps
) => {
  return (
    <div
      onClick={() => {
        props.setSelectedProductId(props.id);
      }}
    >
      <Card style={{ width: 260, padding: 10, marginBottom: 20 }}>
        <Box style={{ marginBottom: 10 }}>
          <Box
            style={{
              width: 250,
              height: 250,
              margin: "auto",
              marginBottom: 10,
            }}
          >
            <img
              style={{ width: 250 }}
              alt="A fully sick product..."
              src={
                props.image
                  ? `${process.env.PUBLIC_URL}/images/${props.image}`
                  : "placeholder-image.png"
              }
            />
          </Box>
          <Typography style={{ color: "text.primary", fontWeight: "bolder" }}>
            {props.name ? props.name : "No Name"}
          </Typography>
        </Box>
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 10,
          }}
        >
          {Object.keys(props.price).map((store) => {
            return <GAPriceCard store={store} {...props.price[store]} />;
          })}
        </Box>
      </Card>
    </div>
  );
};

export default GAProductCard;
