import * as React from 'react';
import { Card, Typography } from '@mui/material'
import { PriceCheckSharp, PrintSharp } from '@mui/icons-material';

interface GAPriceCardProps {
    store?: string
    price?: string
    priceNew?: string
    event?: string
}

const formatPrices = (props: GAPriceCardProps) => {
    const priceFormatter = new Intl.NumberFormat("en-AU", {
        style: 'currency',
        currency: 'AUD'
    })

    if (props.event === "") {
        return <Typography>No Price Data</Typography>
    } else if (props.event === "NORMAL") {
        return <Typography>{props.price ? priceFormatter.format(+props.price) : priceFormatter.format(0)}</Typography>
    } else {
        return <>
            <Typography display="inline" style={{ "textDecoration": "line-through" }}>{props.price ? priceFormatter.format(+props.price) : priceFormatter.format(0)}</Typography>
            <Typography display="inline"> {props.priceNew ? priceFormatter.format(+props.priceNew) : priceFormatter.format(0)}</Typography>
        </>
    }
}

const GAPriceCard: React.FC<GAPriceCardProps> = (props: GAPriceCardProps) => {
    // Change styling based on priceEvent
    return (
        <Card style={{ padding: 10 }}>
            <Typography style={{ "fontSize": 14 }}>{props.store ? props.store : "NO STORE"}</Typography>
            <hr />
            {formatPrices(props)}
            <Typography>{props.event}</Typography>
        </Card>
    )
}

export default GAPriceCard;
